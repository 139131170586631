import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ClientFormUserOnly } from '../../models/ClientFormUser';
import { NotificationResponse } from '../../models/Notification';
import { Placeholders, UserPlaceholder } from '../../models/Placeholders';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import DateUtils from '../../utils/DateUtils';
import NotificationUtils from '../../utils/NotificationUtils';
import { ImageSize, ProfileImageStack } from '../ownership/ProfileImageStack';
import Checkbox from '../shared/form-control/Checkbox';
import Tooltip from '../shared/Tooltip';
import { interpolate } from '../../utils/interpolation/InterpolationUtils';

interface Props {
  notification: NotificationResponse;
  onCheckedChange: (value: boolean) => void;
  checked: boolean;
  onClick: () => void;
}

const NotificationItem = ({ notification, onCheckedChange, checked, onClick }: Props): JSX.Element => {
  const currentUser = useRecoilValue(currentUserAtom);

  const user = useMemo(() => {
    const user = notification.placeholders[Placeholders.PRIMARY_USER] as UserPlaceholder;
    return user || null;
  }, [notification]);

  const text = interpolate(notification.text, notification.placeholders);
  const linkTo = NotificationUtils.getNotificationUrl(notification.placeholders);

  return (
    <div className={`text-color-3 border-gray-5 text-dpm-14 flex items-center rounded-md border-b bg-white p-4`}>
      <div className="item-center flex w-full">
        <Link to={linkTo} onClick={onClick} key={notification.id} className="flex min-w-full max-w-0 items-center gap-4 px-2">
          <span
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Checkbox value={checked} onChange={onCheckedChange} />
          </span>
          <div className="self-center">
            <ProfileImageStack
              size={ImageSize.S}
              users={[
                user
                  ? ({
                      id: user.id,
                      firstName: user.userName.split(' ')[0],
                      lastName: user.userName.split(' ').slice(1).join(' '),
                    } as ClientFormUserOnly)
                  : (currentUser as ClientFormUserOnly),
              ]}
            />
          </div>
          <Tooltip text={text} truncatedTextMode>
            {(tooltip) => (
              <div {...tooltip} className="my-1 flex-grow truncate">
                {text}
              </div>
            )}
          </Tooltip>
          <div className="text-dpm-12 whitespace-nowrap">{DateUtils.formatDateOrTime(new Date(notification.createdUtc))}</div>
        </Link>
      </div>
    </div>
  );
};

export default NotificationItem;
