import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../../hooks/useNotifications';
import NotificationSection from '../notifications/NotificationSection';
import { TabStrip } from '../shared/tab-strip/TabStrip';
import { Heading, HeadingSize } from '../shared/text/Heading';
import { useParams } from 'react-router-dom';

const NotificationList: FC = (): JSX.Element => {
  const params = useParams<{ clientId: string }>();
  const { read, unread } = useNotifications({ enableReadNotifications: true, useCurrentClient: !!params.clientId });
  const { t } = useTranslation('common');

  return (
    <div className="bg-background-1 flex h-full flex-col">
      <Heading size={HeadingSize.H3}>{t('notifications.heading')}</Heading>
      <TabStrip contentClassName="px-2 bg-background-1" enableSticky>
        <TabStrip.TabHeader id="unread" text={t('notifications.categories.unread')} value={null} data-cy="unread-tab" />
        <TabStrip.TabHeader id="read" text={t('notifications.categories.read')} value={null} data-cy="read-tab" />

        <TabStrip.TabContent forId="unread" data-cy="unread-tab-content" onChange={unread.refresh}>
          <NotificationSection
            heading={t('notifications.categories.unread')}
            data={unread.notifications}
            markAsRead={(id) => unread.markRead(id)}
            isLoading={unread.isFetching}
            onShowMore={unread.hasMore ? unread.loadMore : null}
            refresh={unread.refresh}
          />
        </TabStrip.TabContent>
        <TabStrip.TabContent forId="read" data-cy="read-tab-content" onChange={read.refresh}>
          <NotificationSection
            heading={t('notifications.categories.read')}
            data={read.notifications}
            markAsUnRead={(id) => read.markUnRead(id)}
            isLoading={read.isFetching}
            onShowMore={read.hasMore ? read.loadMore : null}
            refresh={read.refresh}
          />
        </TabStrip.TabContent>
      </TabStrip>
    </div>
  );
};

export default NotificationList;
