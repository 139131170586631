import { useTranslation } from 'react-i18next';
import NotificationList from '../components/notifications/NotificationList';
import StaticBreadCrumb from '../components/shared/breadcumb/StaticBreadCrumb';
import TopNavPortal from '../components/layout/top-menu/TopNavPortal';

const NotificationPage = (): JSX.Element => {
  const { t } = useTranslation('common');
  return (
    <div id="notifications" className="bg-background-1 flex h-full flex-col">
      <TopNavPortal>
        <StaticBreadCrumb currentStepName={t('notifications.heading')} />
      </TopNavPortal>
      <div className="border-gray-5 h-0 flex-grow overflow-y-auto border-t p-6">
        <NotificationList />
      </div>
    </div>
  );
};

export default NotificationPage;
