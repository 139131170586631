import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { NotificationResponse } from '../../models/Notification';
import { categoriseByTime, NotificationPeriod } from '../../utils/NotificationUtils';
import Checkbox from '../shared/form-control/Checkbox';
import MailClosedIcon from '../shared/icon/MailClosedIcon';
import MailOpenIcon from '../shared/icon/MailOpenIcon';
import NotificationBellIcon from '../shared/icon/NotificationBellIcon';
import RefreshIcon from '../shared/icon/RefreshIcon';
import Loader from '../shared/Loader';
import Tooltip from '../shared/Tooltip';
import NotificationItem from './NotificationItem';

type Props = {
  className?: string;
  data: NotificationResponse[];
  isLoading: boolean;
  heading: string;
  markAsRead?: (id: string[]) => void;
  markAsUnRead?: (id: string[]) => void;
  onShowMore?: (() => void) | null;
  refresh: () => void;
};

const NotificationSection: FC<Props> = (props) => {
  const { className, data, isLoading, heading, onShowMore, markAsRead, markAsUnRead, refresh } = props;
  const { t } = useTranslation(['common', 'home-page']);
  const [checked, setChecked] = useState<Record<string, boolean>>({});

  const unread = useMemo(() => !!markAsRead, [markAsRead]);

  const noNotifications = data.length === 0 && !isLoading;

  const [lastElementRef] = useInfiniteScroll(onShowMore ? onShowMore : null, isLoading);

  const categorisedData = useMemo(() => categoriseByTime(data, t), [data, t]);

  const anyChecked = useMemo(() => {
    const values = Object.values(checked);
    return values.length > 0 && Object.values(checked).some((x) => x);
  }, [checked]);

  const allChecked = useMemo(() => {
    const values = Object.values(checked);
    return values.length > 0 && data.length === values.length && Object.values(checked).every((x) => x);
  }, [checked, data.length]);

  const toggleAll = useCallback(() => {
    const result: Record<string, boolean> = {};
    for (const notif of data) {
      result[notif.id] = !anyChecked;
    }

    setChecked(result);
  }, [anyChecked, data]);

  const markAsReadInternal = useCallback(() => {
    if (markAsRead) {
      const ids = Object.entries(checked)
        .filter(([_, value]) => value)
        .map(([key, _]) => key);

      markAsRead(ids);
      setChecked((prev) => {
        const result = { ...prev };
        for (const id of ids) {
          delete result[id];
        }

        return result;
      });
    }
  }, [checked, markAsRead]);

  const markAsUnReadInternal = useCallback(() => {
    if (markAsUnRead) {
      const ids = Object.entries(checked)
        .filter(([_, value]) => value)
        .map(([key, _]) => key);

      markAsUnRead(ids);
      setChecked((prev) => {
        const result = { ...prev };
        for (const id of ids) {
          delete result[id];
        }

        return result;
      });
    }
  }, [checked, markAsUnRead]);

  return noNotifications ? (
    <div className={`text-dpm-20 flex h-full w-full flex-col items-center justify-center text-center ${className}`}>
      <NotificationBellIcon className="h-24 w-24 bg-white p-6 text-black" />
      <div>{t('common:notifications.no-notifications', { type: heading.toLowerCase() })}</div>
      <div className="text-gray-2">{t('common:notifications.no-notifications-subtitle')}</div>
    </div>
  ) : (
    <div className={`flex flex-col ${className}`}>
      <div className="flex items-center gap-4 p-6">
        <Checkbox value={allChecked} indeterminate={anyChecked && !allChecked} onChange={() => toggleAll()} />
        {anyChecked && (
          <Tooltip text={unread ? t('common:notifications.mark-as-read') : t('common:notifications.mark-as-un-read')}>
            {(tooltip) => (
              <div {...tooltip} className="flex items-center">
                {unread && <MailOpenIcon onClick={markAsReadInternal} className="h-5 w-5" />}
                {!unread && <MailClosedIcon onClick={markAsUnReadInternal} className="h-5 w-5" />}
              </div>
            )}
          </Tooltip>
        )}
        {!anyChecked && (
          <Tooltip text={t('home-page:notifications.refresh')}>
            {(tooltip) => (
              <div {...tooltip} className="flex items-center">
                <RefreshIcon onClick={refresh} className="h-5 w-5" />
              </div>
            )}
          </Tooltip>
        )}
      </div>
      <div className="flex flex-col">
        {(categorisedData || []).map((category: NotificationPeriod, i) => (
          <div key={v4()} className="flex flex-col">
            <div className="bg-accent-light-5 px-2 py-4 font-medium">{category.period}</div>
            {category.notifications?.map((notification, j) => {
              const isLast = categorisedData.length === i + 1 && category.notifications?.length === j + 1;

              return (
                <div key={notification.id} ref={isLast ? lastElementRef : undefined}>
                  <NotificationItem
                    notification={notification}
                    checked={!!checked[notification.id]}
                    onCheckedChange={(checked) => setChecked((prev) => ({ ...prev, [notification.id]: checked }))}
                    onClick={() => unread && markAsRead && markAsRead([notification.id])}
                  />
                </div>
              );
            })}
          </div>
        ))}
      </div>
      {isLoading && (
        <div className="relative py-16">
          <Loader size={14} />
        </div>
      )}
    </div>
  );
};

export default NotificationSection;
